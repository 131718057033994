import { importConfigurationReducer } from '@dbh/configuration-redux';
import { importCountriesSaga, importCountriesReducer } from '@dbh/countries-redux';
import { isServerSideRendering } from '@dbh/environment';
import { importLanguageProviderSagas } from '@dbh/language-provider-redux';
import { importDateFnsLocalesSagas } from '@dbh/date-fns-locales-redux';
import { importLoggerSagas } from '@dbh/logger-redux';
import { importCancellableReduxSagaSpawnSaga } from '@dbh/cancellable-redux-saga-spawn';
import { importRoutingSaga } from '@dbh/routing-redux';
import { importUserAreaReducer } from '@dbh/user-area-redux';
import { importWebcontrolsSagas, importWebcontrolsReducer } from '@dbh/webcontrols-redux';
import { importRequestQueueSagas, importRequestQueueReducer } from '@dbh/request-queue-redux';
import { importWebpagesReducer } from '@dbh/webpages-redux';
import { importFrontEndLogicRedirectBrowserOnlySagas, importFrontEndLogicRedirectReducer } from '@dbh/front-end-logic-redirect-redux';
import { importUserAreaFormsSaga } from '@dbh/user-area-forms';

/*
 *
 * SSR: `@dbh/app` required "global" sagas and reducers to be always loaded in `SSR`.
 *
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * PERFORMANCE AND BUNDLE SIZE WARNING PERFORMANCE AND BUNDLE SIZE WARNING
 * Before adding something here, be absolutely, completely sure it is required
 * globally by all the application. Otherwise you will just slow down the
 * site and add more ``KBs`. Use the `ssr.js` files if you need to load sagas
 * and reducers for a specific page or container in server side rendering.
 */// We import from the `@dbh/routing-redux` package a `promise` that imports the
// Consider using `withSaga` and `ssr.js` instead for sagas that are
// not needed in the whole app but are associated to a/certain container/s
// (better performance, less code loaded, easier code splitting).
const importSagaPromises=[importRoutingSaga,importLoggerSagas,importCancellableReduxSagaSpawnSaga,import('./index-70c2a1e5.js'/* webpackChunkName: 'dbh.app.sagas' */),importLanguageProviderSagas,importDateFnsLocalesSagas,importCountriesSaga,import('@dbh/webpages-redux-load-sagas'/* webpackChunkName: 'dbh.webpages-redux-load-sagas' */),importWebcontrolsSagas,importUserAreaFormsSaga,importRequestQueueSagas,!isServerSideRendering()&&importFrontEndLogicRedirectBrowserOnlySagas].filter(Boolean),importReducerPromises=[importUserAreaReducer,importConfigurationReducer,importCountriesReducer,importWebpagesReducer,importWebcontrolsReducer,importRequestQueueReducer,importFrontEndLogicRedirectReducer];// "Global" app reducers to always load when starting the app.
// Consider using `withReducers` and `ssr.js` instead for reducers that are
// not needed in the whole app but are associated to a/certain container/s
// (better performance, less code loaded, easier code splitting).
var ssr = [{importSagaPromises,importReducerPromises}];

export { ssr as default };
